<template>
    <section>
        <modal 
        ref="modalFusionarProductos" 
        titulo="Fusionar Productos" 
        tamano="modal-xl" 
        :no-aceptar="true" 
        adicional="Fusionar" 
        :height="50"
        :cargando="loadingFusionar" 
        @adicional="fusionar"
        >
            <div v-loading="loadingFusionar" class="row mx-0 justify-center mb-3">
                <div class="col-6 px-0">
                    <!-- <el-input v-model="buscar" class="w-100 br-20" placeholder="Buscar producto a fusionar" /> -->
                    <el-select
                    v-model="idProductoSelect"
                    filterable
                    remote
                    reserve-keyword
                    placeholder="Buscar producto a fusionar"
                    class="w-100 br-20"
                    :remote-method="buscador"
                    :loading="loading"
                    @change="handleSelect"
                    >
                        <el-option
                        v-for="item in productos"
                        :key="item.id"
                        :label="item.name_producto"
                        :value="item.id"
                        :disabled="item.disabled"
                        />
                    </el-select>
                    <p class="text-center mt-3 text-general">
                        Seleccione el producto que se guardará como definitivo
                    </p>
                </div>
            </div>
            <div v-for="(data, idx) in seleccionados" :key="idx" class="row mx-0 mb-3">
                <div class="col-1 pr-1 d-middle-center">
                    <input type="radio" class="option-input radio" name="example" :checked="data.id===idProducto" @change="itemSeleccionado(data.id)" />
                </div>
                <div class="col-auto px-0">
                    <img :src="data.imagen" width="89" height="89" class="br-12" />
                </div>
                <div class="col d-flex flex-column justify-content-around">
                    <p class="text-general f-14">{{ data.nombre }}</p>
                    <p class="text-g-light f-14"> {{ data.presentacion }} </p>
                </div>
                <div class="col-1 d-flex flex-column justify-content-center align-items-center">
                    <el-tooltip placement="bottom" content="Venta por Cedis" effect="light">
                        <i class="icon-warehouse hover-theme text-g-light f-20" />
                    </el-tooltip>
                    <p class="text-general f-18 text-center f-500">
                        {{ data.cantCompra }}
                    </p>
                </div>
                <div class="col-1 d-flex flex-column justify-content-center align-items-center">
                    <el-tooltip placement="bottom" content="Venta por leeche" effect="light">
                        <i class="icon-leechero-cap hover-theme text-g-light f-20" />
                    </el-tooltip>
                    <p class="text-general f-18 text-center f-500">
                        {{ data.cantVenta }}
                    </p>
                </div>
                <div class="col">
                    <p class="text-general f-18 f-500">Creado Por:</p>
                    <p class="text-general" v-text="data.creador.nombre" />
                    <p class="text-general">
                        {{ formatearFecha(data.created_at) }}
                    </p>
                </div>
                <div class="col-auto d-middle-center">
                    <el-tooltip placement="bottom" content="Quitar" effect="light">
                        <i class="icon-cancel f-20 text-general cr-pointer" @click="retirar(data.id)" />
                    </el-tooltip>
                </div>
                <div class="col-12 mt-2">
                    <div class="row mx-0">
                        <div class="col-1" />
                        <div class="col-1" />
                        <div v-for="(cat, idx2) in data.categorias" :key="`in-${idx2}`" class="bg-general3 text-white br-12 f-12 px-3 py-1 mr-2">
                            {{ cat.nombre }}
                        </div>
                    </div>
                </div>
            </div>
        </modal>
        <modalEliminar ref="modalConfirmarFusionar" titulo="Fusionar productos" @eliminar="confirmarFusionar">
            <div class="row mx-0 mb-2 justify-center text-center">
                {{ msg_fusionar }}
            </div>
        </modalEliminar>
    </section>
</template>

<script>
import Productos from "~/services/productos/productos"

export default {
    data(){
        return {
            buscar: '',
            idProductoSelect: null,
            idDefinitivo: null,
            productos:[],
            seleccionados: [],
            loading: false,
            loadingFusionar: false,
            msg_fusionar: ''
        }
    },
    methods: {
        toggle(){
            this.limpiarVista()
            this.remoteMethod()
            this.$refs.modalFusionarProductos.toggle();
        },
        async buscador(query){
            this.delay(this.remoteMethod(query))
        },
        async remoteMethod(query= null){
            try {
                this.loading = true
                const {data} = await Productos.buscadorProductoConPresentacion({text: query})
                this.loading = false
                const temp = _.clone(this.seleccionados)
                const tempProducts = _.clone(data.productos)
                const arraySeleccionados = temp.map(e => e.id)
                let copyProductos = tempProducts.map(e => {
                    if(arraySeleccionados.includes(e.id)){
                        return {...e, disabled: true}
                    }else{
                        return {...e, disabled: false}
                    }
                })
                this.productos = copyProductos
            } catch (e){
                this.loading = false
                this.error_catch(e)
            }
        },

        async handleSelect(item){
            try {
                if(item){
                    this.loading = true

                    const {data} = await Productos.informacionProductoSeleccinado({id_producto: item})
                    this.loading = false

                    let newProducto = {...data.producto, disabled: true}
                    // console.log('newProducto',newProducto);
                    if(!this.seleccionados.some(e => e.id=== data.producto.id)){
                        this.seleccionados.push(newProducto)
                        // console.log('this.seleccionados ', this.seleccionados );
                    }else{
                        // console.log('ya lo tieen');
                    }
                    const index = this.productos.findIndex(e => e.id=== newProducto.id)
                    if(index >=0){
                        this.productos[index].disabled = true
                    }
                }
            } catch (e){
                this.loading = false
                this.error_catch(e)
            }
        },
        retirar(idProducto){
            this.seleccionados = this.seleccionados.filter( e => e.id !== idProducto)
            if(this.idDefinitivo=== idProducto) this.idDefinitivo = null
            const index = this.productos.findIndex(e => e.id=== idProducto)
            if(index >=0){
                this.productos[index].disabled = false
            }
        },
        itemSeleccionado(idProdct){
            this.idDefinitivo = idProdct
        },

        async fusionar(){
            try {
                const payload = {
                    id_definitivo: this.idDefinitivo,
                    id_productos: this.seleccionados.map( e => e.id)
                }
                if(payload.id_productos.length <=1){
                    this.notificacion('Mensaje', 'Selecione al menos 2 productos', 'warning')
                    return false
                }
                if(!payload.id_definitivo){
                    this.notificacion('Mensaje', 'Selecione el producto principal', 'warning')
                    return false
                }
                let cantEliminar = payload.id_productos.length -1
                this.msg_fusionar = `Esta acción eliminará ${cantEliminar} producto(s)`
                this.$refs.modalFusionarProductos.toggle();
                this.$refs.modalConfirmarFusionar.toggle()


            } catch (e){
                this.loadingFusionar = false
                this.error_catch(e)
            }
        },
        async confirmarFusionar(){
            try {
                const payload = {
                    id_definitivo: this.idDefinitivo,
                    id_productos: this.seleccionados.map( e => e.id)
                }
                this.loadingFusionar = true
                const {data} = await Productos.fusionarProductos(payload)
                this.notificacion('Mensaje', 'Producto fusionado con exito', 'success')
                this.loadingFusionar = false
                this.$emit('update')
                this.$refs.modalConfirmarFusionar.toggle()
            } catch (e){
                this.error_catch(e)
            }
        },
        limpiarVista(){
            this.idProducto = null
            this.idDefinitivo = null
            this.idProductoSelect = null
            this.productos=[]
            this.seleccionados=[]
            this.loading= false
        }
    }
}
</script>

<style lang="scss" scoped>
.br-12{
    border-radius: 12px !important;
}
.hover-theme:hover{
    color: var(--text-general) !important;
}
</style>
